import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger);

gsap.defaults({
  ease: "power1.inOut",
  duration: 0.5,
});

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: 0.5,
      ease: "power1.inOut",
      onComplete: () => {
        el.dispatchEvent(new Event("lazyloaded"));
      },
    });
  },
});

// --------------------------------------------------
// Loader animations
// --------------------------------------------------

let mm = gsap.matchMedia();

const loader = document.querySelector(".loader");
if (loader) {
  var tl = gsap.timeline();

  mm.add("(max-width: 1000px)", () => {
    tl.to(
      ".loader__logo--left svg",
      {
        bottom: "5px",
        duration: 0.5,
        ease: "ease",
      },
      "+=.5"
    );
    tl.to(
      ".loader__logo--right svg",
      {
        top: "5px",
        duration: 0.5,
        ease: "ease",
      },
      "-=.5"
    );
  });

  tl.to(".loader", { duration: 1, yPercent: -100, ease: "ease" }, 1.2);

  // select("body").classList.remove("is-loading");
}

// --------------------------------------------------
// Home page filters
// --------------------------------------------------

document.addEventListener("DOMContentLoaded", () => {
  const filterOptions = document.querySelectorAll(".work-filters__option");
  if (filterOptions) {
    const gridItems = document.querySelectorAll(".grid-item");

    filterOptions.forEach((option) => {
      option.addEventListener("click", function () {
        const filter = this.getAttribute("data-filter");

        // Remove .active class from all filter options
        filterOptions.forEach((option) => option.classList.remove("active"));
        // Add .active class to the clicked filter option
        this.classList.add("active");

        // Show or hide grid items based on the selected filter
        gridItems.forEach((item) => {
          const category = item.getAttribute("data-category");
          if (filter === "All" || filter === category) {
            item.classList.remove("hidden");
          } else {
            item.classList.add("hidden");
          }
        });
      });
    });
  }
});

// --------------------------------------------------
// About page filters
// --------------------------------------------------

document.addEventListener("DOMContentLoaded", () => {
  const aboutTemplate = document.querySelector(".about-template");
  const filterOptions = document.querySelectorAll(".about-filters__option");

  if (aboutTemplate) {
    if (window.innerWidth < 1000) {
      const gridItems = document.querySelectorAll(".about-item");

      filterOptions.forEach((option) => {
        option.addEventListener("click", function () {
          const filter = this.getAttribute("data-filter");

          // Remove .active class from all filter options
          filterOptions.forEach((option) => option.classList.remove("active"));
          // Add .active class to the clicked filter option
          this.classList.add("active");

          // Show or hide grid items based on the selected filter
          gridItems.forEach((item) => {
            const category = item.getAttribute("data-category");
            if (filter === category) {
              item.classList.remove("hidden");
            } else {
              item.classList.add("hidden");
            }
          });
        });
      });
    } else {
      const gridItems = document.querySelectorAll(".desktop-about-item");

      filterOptions.forEach((option) => {
        option.addEventListener("click", function () {
          const filter = this.getAttribute("data-filter");

          // Remove .active class from all filter options
          filterOptions.forEach((option) => option.classList.remove("active"));
          // Add .active class to the clicked filter option
          this.classList.add("active");

          // Show or hide grid items based on the selected filter
          gridItems.forEach((item) => {
            const category = item.getAttribute("data-category");
            if (filter === category) {
              item.classList.remove("hidden");
            } else {
              item.classList.add("hidden");
            }
          });
        });
      });
    }
  }
});

// --------------------------------------------------
// About scroll animation
// --------------------------------------------------

const aboutTemplate = document.querySelector(".about-template");
if (aboutTemplate) {
  // change opacity of photos
  mm.add("(max-width: 1000px)", () => {
    gsap.utils.toArray(".about-items__content img").forEach((hiddenImage) => {
      ScrollTrigger.create({
        trigger: hiddenImage,
        start: "top 60%",
        end: "bottom 60%",
        // markers: true,
        scrub: true,
        onEnter: () => {
          hiddenImage.classList.add("in-view");
          console.log("onEnter");
          console.log(hiddenImage);
        },
        onEnterBack: () => {
          hiddenImage.classList.add("in-view");
          console.log("onEnterBack");
        },
        onLeave: () => {
          hiddenImage.classList.remove("in-view");
          console.log("onLeave");
        },
        onLeaveBack: () => {
          hiddenImage.classList.remove("in-view");
          console.log("onLeaveBack");
        },
      });
    });
  });
}
// --------------------------------------------------
// Credits slide animation
// --------------------------------------------------

const workTemplate = document.querySelector(".work-template");
if (workTemplate) {
  document.addEventListener("DOMContentLoaded", function () {
    const creditsButton = document.querySelector(".credits-button");
    const mobWorkCredits = document.querySelector(".mob-work-credits");
    const creditsText = document.querySelector(".work-credits");
    const navLogo = document.querySelectorAll(".navbar-logo path");
    const navButtons = document.querySelectorAll(".navbar-text-button");
    const workCloseLink = document.getElementById("work-close-link");
    let isCreditsVisible = false;

    const slideUp = gsap
      .timeline({ paused: true })
      .to(creditsText, { color: "var(--white)", duration: 0 })
      .to(mobWorkCredits, { top: 0, duration: 0.5 })
      .to(navLogo, { fill: "var(--white)", duration: 0 })
      .to(navButtons, { color: "var(--white)", duration: 0 });

    creditsButton.addEventListener("click", function () {
      if (isCreditsVisible) {
        slideUp.reverse();
      } else {
        slideUp.play();
      }
      isCreditsVisible = !isCreditsVisible;
    });

    // Close credits when the close link is clicked
    workCloseLink.addEventListener("click", function (e) {
      if (isCreditsVisible) {
        e.preventDefault(); // Prevent default link behavior
        slideUp.reverse(); // Close the credits
        isCreditsVisible = false;
      }
    });
  });
}
